import {faVideo, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from "react";
import { ControlButton, MenuItem } from 'livekit-react'

export interface VideoSelectButtonProps {
  isEnabled?: boolean;
  onClick?: () => void;
  onSourceSelected?: (device: MediaDeviceInfo) => void;
  disableText?: string;
  enableText?: string;
  className?: string;
  popoverContainerClassName?: string;
  popoverTriggerBtnClassName?: string;
  popoverTriggerBtnSeparatorClassName?: string;

  icon: IconDefinition,
  label: string,
  optionsList: string[],
  callbackActions: (() => void)[],
  defaultIndex?: number,
  onChange?: ((label: string) => void)
}

export const SelectButton = ({
  className,
  popoverContainerClassName,
  popoverTriggerBtnClassName,
  popoverTriggerBtnSeparatorClassName,
  label,
  isEnabled=true,
  defaultIndex,
  optionsList,
  callbackActions,
  icon=faVideo,
  onChange=() => {},
}: VideoSelectButtonProps) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [selection, setSelection] = useState(label || optionsList[defaultIndex || 0]);

  useEffect(() => {
    setMenuItems(
      optionsList.map((item) => {
        return { label: item };
      })
    );
  }, []);

  const handleMenuItem = (item: MenuItem) => {
    setSelection(item.label);
    return onChange(item.label);
  };

  const callActions = () => {
    callbackActions[optionsList.indexOf(selection)]();
  }

  return (
    <ControlButton
      disabled={!isEnabled}
      label={selection}
      icon={icon}
      menuItems={menuItems}
      onMenuItemClick={handleMenuItem}
      onClick={callActions}
      className={className}
      popoverContainerClassName={popoverContainerClassName}
      popoverTriggerBtnClassName={popoverTriggerBtnClassName}
      popoverTriggerBtnSeparatorClassName={popoverTriggerBtnSeparatorClassName}
    />
  );
};
