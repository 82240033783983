import 'livekit-react/dist/index.css'
import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {HomePage} from './HomePage'
import {RoomPage} from './RoomPage'
import {PreJoinPage} from './PreJoinPage';

const App = () => {
    return (
        <div className="container">
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/join" element={<PreJoinPage/>}/>
                <Route path="/room/:roomName" element={<RoomPage/>}/>
            </Routes>
        </div>
    )
}

export default App
