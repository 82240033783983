import {faEdit, faSave, faVideo} from '@fortawesome/free-solid-svg-icons';
import {ControlButton} from 'livekit-react'
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {SelectButton} from './Dropdown';

export const HomePage = () => {
    const [userName, setUserName] = useState<string>(sessionStorage.getItem('userName') || '');
    const [editingUserName, setEditingUserName] = useState<boolean>(userName === '');
    const [roomName, setRoomName] = useState<string>('');
    const [showRoomName, setShowRoomName] = useState<boolean>(false);
    const query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();

    const updateUserName = async () => {
        sessionStorage.setItem('userName', userName);
        setEditingUserName(false);
    }

    const createNewRoom = async () => {
        setRoomName('');
        setShowRoomName(false);
        sessionStorage.removeItem('roomName');
        navigate({
            pathname: '/join',
        })
    }

    const joinRoom = async () => {
        setShowRoomName(true);
        if (roomName.toString().trim().length === 8) {
            navigate({
                pathname: '/join',
                search: "?" + new URLSearchParams({room: roomName}).toString()
            })
        }
    }

    useEffect(() => {
        sessionStorage.setItem('roomName', roomName);
    }, [roomName]);

    return (
        <div className="prejoin">
            <main>
                <h2>Conferencing</h2>
                <hr/>
                <div className="userInfo">
                    <div>
                        <div className="label">
                            Full name (Authentication)
                        </div>
                        <div>
                            <input type="text" placeholder={'Full Name'} name="userName" value={userName} readOnly={!editingUserName}
                                   onChange={e => setUserName(e.target.value)} />
                        </div>
                    </div>
                    <div>
                        <div style={{paddingTop: "1.75rem"}}>
                            {((userName == '' || editingUserName) && <ControlButton
                                label="Save"
                                disabled={userName == '' || !editingUserName}
                                icon={faSave}
                                onClick={updateUserName} />)}
                            {(userName == '' || !editingUserName && <ControlButton
                                label="Change"
                                disabled={editingUserName}
                                icon={faEdit}
                                onClick={() => {setEditingUserName(true)}} />)
                            }
                        </div>
                    </div>
                    <hr/>
                    <hr/>
                    <div style={{paddingTop: "0.25rem"}}>
                        {showRoomName &&
                          (<input type="text" placeholder={'Room to Join'} name="roomName" value={roomName}
                                 onChange={e => setRoomName(e.target.value)} />)
                        }
                    </div>
                    <div>
                        { ((userName != '' && !editingUserName) &&
                        <SelectButton
                          label="Start"
                          optionsList={["Create New Room", "Join Room"]}
                          callbackActions={[createNewRoom, joinRoom]}
                          icon={faVideo}
                          onChange={(label) => {setShowRoomName(label === 'Join Room')}}
                        /> )}
                    </div>
                </div>
            </main>
            <footer>
                thatcoderagain!
            </footer>
        </div>
    )
}
