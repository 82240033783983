export const createRoomApi = async () => {
  const baseUrl = process.env.REACT_APP_API_HOST_URL || '';
  return await fetch(`${baseUrl}/room/create`, {
        method: 'POST',
        cache: 'no-cache',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({})
    })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                throw new Error(`Error: ${data.message}`);
            }
            sessionStorage.setItem('roomName', data.room.name);
            sessionStorage.setItem('room', JSON.stringify({name: data.room.name, creationTime: data.room.creationTime}));
            return data.room.name;
        });
}

export const createTokenApi = async (roomName: string, userName: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST_URL || '';
  return await fetch(`${baseUrl}/room/token/create`, {
        method: 'POST',
        cache: 'no-cache',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({roomName: roomName, userName: userName})
    })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                throw new Error(`Error: ${data.message}`);
            }
            sessionStorage.setItem('token', data.token);
            return data.token;
        });
}